<template>
  <div ref="refCartModal" class="modal" tabindex="0" @keydown.esc="closeModal">
    <div v-on-click-outside="closeModal" class="modal__wrapper">
      <div class="cart popup" :class="{ empty: isEmpty }">
        <div class="cart__head">
          <p class="cart__title">
            {{ _T("@Cart") }}
          </p>
          <button class="cart__cancel" @click.stop.prevent="closeModal">
            <svg
              class="cart__cancel-icon"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="cart__cancel-state"
                d="M9.00972 22.9902L15.9999 16M22.9901 9.00981L15.9999 16M15.9999 16L9.00972 9.00981M15.9999 16L22.9901 22.9902"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div class="cart__empty">
          <div class="cart__empty-icon">
            <img src="~~/assets/icons/empty-basket.svg" alt="empty" />
          </div>
          <h3 class="cart__empty-title">
            {{ _T("@Add items to cart") }}
          </h3>
          <MyLink name="catalog" class="go-to" @click.stop.prevent="closeModal">
            {{ _T("@Go to catalog") }}
          </MyLink>
        </div>
        <ul class="card__list">
          <li
            v-for="cartProduct in cart"
            :key="cartProduct.product.id"
            class="card__item"
          >
            <div class="card__prod">
              <ProductLink
                class="card__img-w"
                :product="cartProduct.product"
                @click.stop.prevent="closeModal"
              >
                <img
                  class="card__img"
                  :src="cartProduct.product.getMainImage('medium')"
                  :alt="cartProduct.product.getName()"
                />
              </ProductLink>
              <div class="card__info">
                <div class="card__main">
                  <ProductLink
                    class="card__title"
                    :product="cartProduct.product"
                    @click.stop.prevent="closeModal"
                  >
                    {{ cartProduct.product.getName() }}
                  </ProductLink>
                  <div class="card__code">
                    {{ _T("@Code") }}
                    <span>{{ cartProduct.product.code }}</span>
                  </div>
                </div>
                <div class="card__footer">
                  <div class="card__counter">
                    <div
                      class="card__counter-minus"
                      @click="decrease(cartProduct)"
                    >
                      <img src="@/assets/icons/minus.svg" alt="minus" />
                    </div>
                    <input
                      type="number"
                      class="card__counter-input"
                      min="1"
                      max="999"
                      :value="cartProduct.quantity"
                      @blur="setQuantity($event, cartProduct)"
                    />
                    <div
                      class="card__counter-plus"
                      @click="increase(cartProduct)"
                    >
                      <img src="@/assets/icons/plus.svg" alt="plus" />
                    </div>
                  </div>
                  <div
                    class="card__price"
                    :class="{
                      discount: cartProduct.product.getPrice(
                        Product.PRICE_TYPE.PREVIOUS,
                      ),
                    }"
                  >
                    <span
                      v-if="
                        undefined !==
                        cartProduct.product.getPrice(
                          Product.PRICE_TYPE.PREVIOUS,
                        )
                      "
                      class="card__price-sale-old"
                    >
                      {{
                        priceFormatter(
                          cartProduct.product.getPrice(
                            Product.PRICE_TYPE.PREVIOUS,
                          ) * cartProduct.quantity,
                        )
                      }}
                      &#8372;
                    </span>
                    <span class="card__price-sale-new">
                      {{
                        priceFormatter(
                          cartProduct.product.getPrice(
                            Product.PRICE_TYPE.CURRENT,
                          ) * cartProduct.quantity,
                        )
                      }}
                      &#8372;
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <button class="card__del" @click.stop.prevent="remove(cartProduct)">
              <img src="@/assets/icons/trash.svg" alt="delete product" />
            </button>
          </li>
        </ul>
        <div class="footer">
          <button class="footer__continue" @click.stop.prevent="closeModal">
            {{ _T("@Continue Shopping") }}
          </button>
          <div class="footer__receipt">
            <div class="footer__receipt-sum">
              <span class="footer__receipt-sum-label">{{ _T("@Total") }}:</span>
              <span class="footer__receipt-sum-price"
                >{{ priceFormatter(amount) }} &#8372;</span
              >
            </div>
            <MyLink
              class="footer__receipt-submit"
              name="checkout"
              @click.stop.prevent="closeModal"
            >
              {{ _T("@Place an Order") }}
            </MyLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCartStore } from "~~/store/cartStore";
import { vOnClickOutside } from "@vueuse/components";
import { priceFormatter } from "~/utils/formatters.utils";
import { Product } from "~/models/product.model";

const emits = defineEmits(["close"]);

const cartStore = useCartStore();
const {
  getCart: cart,
  getTotalAmount: amount,
  isEmpty,
} = storeToRefs(cartStore);

const itemQtyAdd = ref(0);
const itemQtyRemove = ref(0);
const refCartModal = ref(null);

const increase = (item) => {
  item.quantity++;

  itemQtyAdd.value++;
  debounced(item, itemQtyAdd.value);
};

const decrease = (item) => {
  if (item.quantity === 1) {
    return;
  }

  item.quantity--;
  itemQtyRemove.value--;

  debounced(item, itemQtyRemove.value);
};

const setQuantity = (event, item) => {
  if (event.target.value < 1) {
    event.target.value = 1;
  }

  const prevQty = item.quantity;
  const currQty = parseInt(event.target.value);

  if (prevQty !== currQty) {
    debounced(item, currQty - prevQty);
  }
};

const debounced = useDebounceFn((item, qty) => {
  cartStore.setQuantity(item, qty);
  itemQtyAdd.value = 0;
  itemQtyRemove.value = 0;
}, 300);

function remove(item) {
  cartStore.removeItem(item);
}

function closeModal() {
  emits("close");
  document.documentElement.style.overflow = "auto";
}

onMounted(() => {
  refCartModal.value.focus();
  document.documentElement.style.overflow = "hidden";
});
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.modal__wrapper {
  max-width: 1040px;
}

.cart {
  width: 100%;
  min-height: 50%;

  position: relative;

  @include flex-container(column, center, center);
  gap: 24px;

  padding-bottom: 0;

  border-radius: 8px;
  background-color: white;

  @include bigMobile {
    height: 100%;

    @include flex-container(column, flex-start, center);
  }

  &.empty {
    .cart__empty {
      @include flex-container(column, flex-start, center);

      padding-bottom: 16px;
    }

    .card__list {
      display: none;
    }

    .footer {
      display: none;
    }
  }

  &__head {
    @include flex-container(row, space-between, center);
    width: 100%;

    padding: 16px;

    border-bottom: 1px solid var(--color-sky-base);

    @include mobile {
      padding-block: 8px;
    }
  }

  &__title {
    @include font(25, 35);
    letter-spacing: 0.02em;

    @include mobile {
      @include font;
    }
  }

  &__cancel {
    font-size: 0;

    &:hover .cart__cancel-state {
      stroke: var(--color-red-dark);
    }
  }

  &__empty {
    display: none;

    gap: 24px;
  }

  &__empty-title {
    @include font(20, 28);
    letter-spacing: 0.02em;
    color: #2b2b2b;
  }
}

.card {
  &__list {
    width: 100%;
    height: 60dvh;

    @include flex-container(column, flex-start);

    padding-left: 16px;
    padding-right: 8px;
    margin-right: 8px;

    overflow: auto;

    @include mobile {
      height: 100%;

      padding-inline: 8px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 0;

      @include mobile {
        width: 4px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: var(--color-sky-dark);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary-base);
      border-radius: 100px;

      &:hover {
        background-color: var(--color-primary-dark);
      }
    }
  }

  &__item {
    width: 100%;

    @include flex-container(row, space-between, center);
    gap: 24px;

    border-bottom: 1px solid #cdcfd0;

    padding-block: 8px;

    @include mobile {
      align-items: flex-start;
      gap: 8px;
    }
  }

  &__prod {
    width: 100%;

    @include flex-container(row, space-between, center);
    gap: 16px;

    @include mobile {
      gap: 8px;
    }
  }

  &__img-w {
    max-width: 135px;
    width: 100%;
    height: 135px;

    @include mobile {
      max-width: 64px;
      height: 64px;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  &__info {
    max-width: 756px;
    width: 100%;

    @include flex-container;
    gap: 16px;

    @include mobile {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__main {
    @include flex-container(column, center);
    gap: 4px;
  }

  &__title {
    @include font(16, 22, 600);
    letter-spacing: 0.02em;

    transition: 0.2s ease;

    @include mobile {
      @include font(14, 20, 500);
    }

    &:hover {
      color: var(--color-primary-base);
    }
  }

  &__code {
    @include font(12, 16);
    letter-spacing: 0.02em;
  }

  &__footer {
    max-width: 256px;
    width: 100%;

    @include flex-container(row, space-between, center);
    gap: 16px;

    @include mobile {
      max-width: 180px;

      flex-wrap: nowrap;
      gap: 8px;
    }
  }

  &__counter {
    max-width: 124px;
    width: 100%;

    @include flex-container(row, center, center);
    gap: 8px;

    border: 1px solid #e3e5e6;
    border-radius: 60px;

    padding: 8px 12px;

    @include mobile {
      max-width: 88px;

      gap: 4px;

      padding: 4px 8px;
    }
  }

  &__counter-minus {
    cursor: pointer;

    @include mobile {
      width: 16px;
    }
  }

  &__counter-input {
    width: 36px;

    @include font(14, 24);
    text-align: center;

    cursor: auto;

    @include mobile {
      @include font(12, 16);
    }
  }

  &__counter-plus {
    cursor: pointer;

    @include mobile {
      width: 16px;
    }
  }

  &__price {
    @include font(18, 26, 700);
    letter-spacing: 0.02em;

    &.discount {
      @include flex-container(column, center, center);
      gap: 4px;

      .card__price-sale-new {
        color: red;
      }
    }

    @include mobile {
      @include font(14, 20, 700);
    }
  }

  &__price-sale {
    @include flex-container(column, center, flex-end);
    gap: 8px;

    @include mobile {
      gap: 4px;
    }
  }

  &__price-sale-old {
    @include font(14, 20);
    letter-spacing: 0.02em;
    text-decoration: line-through;

    @include mobile {
      @include font(12, 16);
    }
  }

  &__price-sale-new {
    @include font(18, 26, 700);
    letter-spacing: 0.02em;

    color: #2b2b2b;

    @include mobile {
      @include font(14, 20, 700);
    }
  }

  &__del {
    width: fit-content;
    height: fit-content;
  }
}

.footer {
  width: 100%;

  @include flex-container(row, space-between, flex-end);
  gap: 16px;

  margin-top: auto;

  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  background-color: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);

  padding: 16px;

  @include mobile {
    padding: 8px;
  }

  &__continue {
    @include flex-container(row, center, center);

    @include font(18, 24);
    letter-spacing: 0.02em;
    text-align: center;
    white-space: nowrap;
    color: #4490bb;

    border: 1px solid #4490bb;
    border-radius: 6px;

    padding: 8px 32px;

    transition: 0.2s ease;

    @include mobile {
      display: none;
    }

    &:hover {
      color: white;
      background-color: #4490bb;
    }
  }

  &__receipt {
    @include flex-container(row, flex-end, flex-end);
    flex-wrap: wrap;
    gap: 16px;

    @include mobile {
      width: 100%;

      flex-direction: column;
    }
  }

  &__receipt-sum {
    @include flex-container;
    gap: 16px;

    @include mobile {
      width: 100%;
    }
  }

  &__receipt-sum-label {
    @include font(20, 28);
    letter-spacing: 0.02em;

    @include mobile {
      @include font;
    }
  }

  &__receipt-sum-price {
    @include font(20, 30, 700);

    @include mobile {
      @include font(16, 22, 700);
    }
  }

  &__receipt-submit {
    @include flex-container(row, center, center);

    @include font(18, 24);
    letter-spacing: 0.02em;
    text-align: center;
    white-space: nowrap;
    color: white;

    background-color: var(--color-primary-base);
    border: 1px solid var(--color-primary-base);
    border-radius: 6px;

    padding: 8px 32px;

    transition: 0.2s ease;

    @include mobile {
      width: 100%;
    }

    &:hover {
      background-color: var(--color-primary-dark);
    }
  }
}
</style>
