<template>
  <div
    ref="refAccountModal"
    class="modal"
    tabindex="0"
    @keydown.esc="closeModal"
  >
    <div
      v-on-click-outside="closeModal"
      class="modal__wrapper"
      :class="{
        'sign-up': currentComponent === 'signUp',
        'sign-in': currentComponent === 'signIn',
      }"
    >
      <div class="account-modal">
        <div class="account-modal__header">
          <p class="account-modal__modal-name">
            {{ accountModalComponent[currentComponent].title }}
          </p>
          <button class="account-modal__close" @click.stop.prevent="closeModal">
            <img src="~~/assets/icons/cross.svg" alt="close button" />
          </button>
        </div>
      </div>
      <component
        :is="accountModalComponent[currentComponent].component()"
        @change-component="onChangeComponent"
      />
    </div>
  </div>
</template>

<script setup>
import SignIn from "~/modules/account/components/sign/SignIn.vue";
import SignUp from "~/modules/account/components/sign/SignUp.vue";
import ResetPassword from "~/modules/account/components/sign/ResetPassword.vue";
import {
  useModalStore,
  accountModal,
} from "~/modules/shared/components/modals/store/modalStore";
import { vOnClickOutside } from "@vueuse/components";

const emits = defineEmits(["close"]);

const modalStore = useModalStore();
const { getExtra: extra } = storeToRefs(modalStore);

const currentComponent = ref(null);
const refAccountModal = ref(null);

currentComponent.value = extra.value(accountModal).component;

const accountModalComponent = {
  signIn: {
    component: () => SignIn,
    title: _T("@Entrance"),
  },
  signUp: {
    component: () => SignUp,
    title: _T("@Registration"),
  },
  passwordReset: {
    component: () => ResetPassword,
    title: _T("@Password reset"),
  },
};

function onChangeComponent(component) {
  currentComponent.value = component;
}

function closeModal() {
  emits("close");
  document.documentElement.style.overflow = "auto";
}

onMounted(() => {
  document.documentElement.style.overflow = "hidden";
  refAccountModal.value.focus();
});
</script>

<style lang="scss">
.account-modal {
  border-radius: 8px;

  &__header {
    @include flex-container(row, space-between, center);

    border-bottom: 1px solid #d1d1d1;

    padding: 16px;
    gap: 16px;
  }

  &__modal-name {
    @include font(20, 28);
    letter-spacing: 0.02em;
    color: #2b2b2b;
  }

  &__close {
    @include fixedHW(32px, 32px);

    @include flex-container(row, center, center);

    & img {
      @include fixedHW(14px, 14px);
    }
  }
}

.modal__wrapper {
  &.sign-up {
    max-width: 1044px;

    @include bigMobile {
      max-width: 700px;
    }
  }

  &.sign-in {
    max-width: 700px;

    @include bigMobile {
      max-width: 500px;
    }
  }
}

.registration-enter-active,
.registration-leave-active {
  transition: opacity 0.3s ease;
}

.registration-enter-from,
.registration-leave-to {
  opacity: 0;
}
</style>
